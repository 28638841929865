// src/pages/ContactPage.js
import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@radix-ui/react-accordion";
import { EnvelopeClosedIcon, ChatBubbleIcon } from "@radix-ui/react-icons";
import { Button } from "@radix-ui/themes";

const ContactPage = () => {
  const faqItems = [
    {
      question: "How can I rent a pet?",
      answer:
        'To rent a pet, simply browse the available pets and click the "Rent a Pet" button to start the booking process.',
    },
    {
      question: "How can I list my pet for rent?",
      answer:
        'To list your pet, click on "List Your Pet" in the navigation menu and follow the instructions to add your pet’s details.',
    },
    {
      question: "What are the rental fees?",
      answer:
        "Rental fees depend on the pet’s owner and the length of time you want to rent the pet. You can find this information on each pet’s listing page.",
    },
    {
      question: "Is it safe to rent out my pet?",
      answer:
        "We ensure that renters are verified and that safety protocols are followed. However, you should always meet the renter beforehand and make sure your pet is comfortable.",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 dark:from-gray-900 dark:to-gray-800 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        {/* Page Heading */}
        <h1 className="text-4xl font-bold text-center text-gray-800 dark:text-white mb-8">
          Contact Us
        </h1>

        {/* FAQ Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-gray-800 dark:text-white mb-6 text-center md:text-left">
            Frequently Asked Questions
          </h2>
          <Accordion type="single" collapsible>
            {faqItems.map((item, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger className="w-full text-left bg-gray-100 dark:bg-gray-800 px-4 py-2 rounded-lg mb-4 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700">
                  <div className="flex justify-between items-center">
                    <span className="text-lg font-semibold text-gray-800 dark:text-white">
                      {item.question}
                    </span>
                    <span className="ml-2 text-gray-500 dark:text-gray-400">
                      ▼
                    </span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-4 pb-4">
                  <p className="text-gray-600 dark:text-gray-300">
                    {item.answer}
                  </p>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </section>

        {/* Contact Section */}
        <section className="text-center">
          <h2 className="text-3xl font-semibold text-gray-800 dark:text-white mb-6">
            Still Have Questions?
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
            If you have further questions, feel free to reach out via WhatsApp
            or email.
          </p>
          <div className="flex flex-col md:flex-row justify-center md:space-x-4 space-y-4 md:space-y-0">
            {/* WhatsApp Button */}
            <a
              href="https://wa.me/162856899?text=Hi%21%20I%20want%20to%20ask%20about%20the%20Pet%20Rental%20app%20%28https%3A%2F%2Fpetrental.com%29" // Replace with your WhatsApp number
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-auto"
            >
              <Button
                as="a"
                className="w-full md:w-auto flex justify-center items-center space-x-2 bg-green-500 text-white hover:bg-green-400"
              >
                <ChatBubbleIcon className="w-5 h-5" />
                <span>Contact via WhatsApp</span>
              </Button>
            </a>

            {/* Email Button */}
            <a
              href="mailto:jaywah2008@hotmail.com"
              className="w-full md:w-auto"
            >
              <Button
                as="a"
                variant="solid"
                className="w-full md:w-auto flex justify-center items-center space-x-2 bg-blue-600 text-white hover:bg-blue-500"
              >
                <EnvelopeClosedIcon className="w-5 h-5" />
                <span>Contact via Email</span>
              </Button>
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactPage;
