// src/pages/AboutPage.js
import React from "react";
import { Button } from "@radix-ui/themes";
// import { PawPrintIcon } from "@heroicons/react/24/solid"; // Importing Heroicons' Paw Print Icon
import browsePetImage from "../images/browsepets.jpg";
import listPetImage from "../images/listpet.jpeg";
import rentPetImage from "../images/rentpets.jpeg";

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-blue-50 dark:from-gray-900 dark:to-gray-800 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        {/* Page Heading */}
        <h1 className="text-4xl font-bold text-center text-gray-800 dark:text-white mb-8">
          About Pet Rental App
        </h1>

        {/* Intro Section */}
        <section className="mb-12 text-center">
          <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
            Welcome to Pet Rental, the ultimate platform where pet lovers and
            pet owners come together.
          </p>
          <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
            Whether you're looking to enjoy the company of a furry friend for a
            few hours or days, or you want to rent out your pet while you're
            away, we've got you covered.
          </p>
          {/* <PawPrintIcon className="w-12 h-12 mx-auto text-blue-500 dark:text-white mb-6" /> */}
        </section>

        {/* Why Rent a Pet Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-gray-800 dark:text-white mb-6 text-center">
            Why Rent a Pet?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Card 1: For Renters */}
            <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
                Experience the Joy of Pets
              </h3>
              <p className="text-gray-700 dark:text-gray-300 mb-4">
                Always wanted a pet but aren’t ready for the full-time
                commitment? Renting a pet gives you the chance to enjoy their
                companionship without the long-term responsibility.
              </p>
              <Button
                as="a"
                href="/rent"
                className="bg-blue-600 text-white hover:bg-blue-500"
              >
                Rent a Pet
              </Button>
            </div>

            {/* Card 2: For Owners */}
            <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
                Earn Extra Income or Find Care
              </h3>
              <p className="text-gray-700 dark:text-gray-300 mb-4">
                If you're a pet owner, you can earn extra income by renting out
                your pet when you're busy or away. Rest easy knowing your pet is
                cared for by a fellow animal lover.
              </p>
              <Button
                as="a"
                href="/list-pet"
                className="bg-blue-600 text-white hover:bg-blue-500"
              >
                List Your Pet
              </Button>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="mb-12">
          <h2 className="text-3xl font-semibold text-gray-800 dark:text-white mb-6 text-center">
            How It Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <img
                src={browsePetImage}
                alt="Browse Pets"
                className="mx-auto mb-4"
              />
              <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-2">
                Browse Available Pets
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Find the perfect pet for you by browsing our wide selection of
                dogs and cats available for rental.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <img
                src={rentPetImage}
                alt="Rent a Pet"
                className="mx-auto mb-4"
              />
              <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-2">
                Rent Your Favorite Pet
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Once you've found your furry friend, book them for hours or days
                and enjoy your time together.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <img
                src={listPetImage}
                alt="List a Pet"
                className="mx-auto mb-4"
              />
              <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-2">
                List Your Pet for Rent
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Let others enjoy the company of your pet while you earn income
                or get some extra help with care.
              </p>
            </div>
          </div>
        </section>

        {/* Final Call to Action */}
        <section className="text-center py-8 bg-blue-600 text-white rounded-lg">
          <h2 className="text-3xl font-bold mb-4">
            Join the Pet Rental Community Today
          </h2>
          <p className="text-lg mb-6">
            Whether you're a pet lover looking for companionship or a pet owner
            in need of support, Pet Rental is here for you.
          </p>
          <div className="flex justify-center space-x-4">
            <Button
              as="a"
              href="/rent"
              className="bg-blue-600 text-white hover:bg-blue-500"
            >
              Rent a Pet
            </Button>
            <Button
              as="a"
              href="/list-pet"
              className="bg-blue-600 text-white hover:bg-blue-500"
            >
              List Your Pet
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutPage;
