// src/components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HamburgerMenuIcon } from "@radix-ui/react-icons"; // Radix UI icon for menu
import { Button } from "@radix-ui/themes";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header className="bg-white dark:bg-gray-900 shadow-md">
      <nav className="max-w-7xl mx-auto flex justify-between items-center p-4">
        {/* Logo */}
        <div className="text-2xl font-bold  dark:text-white">
          <Link to="/">PetRental</Link>
        </div>

        {/* Desktop Links */}
        <div className="hidden md:flex space-x-8">
          <Link
            to="/"
            className="text-gray-700 dark:text-gray-300 hover:text-blue-500"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-gray-700 dark:text-gray-300 hover:text-blue-500"
          >
            About
          </Link>
          <Link
            to="/services"
            className="text-gray-700 dark:text-gray-300 hover:text-blue-500"
          >
            Services
          </Link>
          <Link
            to="/contact"
            className="text-gray-700 dark:text-gray-300 hover:text-blue-500"
          >
            Contact
          </Link>
        </div>

        {/* Call to Action Button */}
        <div className="hidden md:flex items-center space-x-4">
          <Button
            as="a"
            href="/signup"
            variant="solid"
            className="bg-blue-600 text-white"
          >
            Get Started
          </Button>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-gray-800 dark:text-gray-300 focus:outline-none"
          >
            <HamburgerMenuIcon className="w-6 h-6" />
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-white dark:bg-gray-800 p-4">
          <Link
            to="/"
            className="block text-gray-800 dark:text-gray-300 py-2 hover:text-blue-500"
            onClick={() => setIsOpen(false)}
          >
            Home
          </Link>
          <Link
            to="/about"
            className="block text-gray-800 dark:text-gray-300 py-2 hover:text-blue-500"
            onClick={() => setIsOpen(false)}
          >
            About
          </Link>
          <Link
            to="/services"
            className="block text-gray-800 dark:text-gray-300 py-2 hover:text-blue-500"
            onClick={() => setIsOpen(false)}
          >
            Services
          </Link>
          <Link
            to="/contact"
            className="block text-gray-800 dark:text-gray-300 py-2 hover:text-blue-500"
            onClick={() => setIsOpen(false)}
          >
            Contact
          </Link>
          <Button
            as="a"
            href="/signup"
            variant="solid"
            className="w-full mt-4 bg-blue-600 text-white"
          >
            Get Started
          </Button>
        </div>
      )}
    </header>
  );
};

export default Navbar;
