import React from "react";
import { Button } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import heroImage from "../images/rent-pets-landingpage.jpg";
import browsePetImage from "../images/browsepets.jpg";
import listPetImage from "../images/listpet.jpeg";
import rentPetImage from "../images/rentpets.jpeg";

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-blue-50 dark:from-gray-900 dark:to-gray-800">
      {/* Hero Section */}
      <section className="flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto py-12 px-4">
        {/* Text Section */}
        <div className="flex-1 text-center md:text-left space-y-6">
          <h1 className="text-5xl font-bold text-gray-800 dark:text-white">
            Rent a Pet, or Rent Out Your Pet Today!
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Looking to spend quality time with a pet or rent out your furry
            friend? We’ve got you covered! Choose from a wide range of dogs and
            cats, available for hours or days.
          </p>
          <div className="flex justify-center md:justify-start space-x-4">
            <Link to="/rent">
              <Button
                as="a"
                className="bg-blue-600 text-white hover:bg-blue-500 hover:cursor-pointer"
              >
                Rent a Pet
              </Button>
            </Link>
            <Link to="/list-pet">
              <Button
                as="a"
                className="bg-blue-600 text-white hover:bg-blue-500 hover:cursor-pointer"
              >
                List Your Pet
              </Button>
            </Link>
          </div>
        </div>

        {/* Hero Image */}
        <div className="flex-1 mt-8 md:mt-0">
          <img
            src={heroImage} // You can replace with an actual pet-related image
            alt="Pets"
            className="rounded-lg shadow-lg"
          />
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-white dark:bg-gray-900 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-gray-800 dark:text-white mb-8">
            How It Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <img src={browsePetImage} alt="Browse" className="mx-auto mb-4" />
              <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                Browse Pets
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Find the perfect pet for you, available for hourly or daily
                rentals.
              </p>
            </div>
            <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <img src={rentPetImage} alt="Rent" className="mx-auto mb-4" />
              <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                Rent Easily
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Book your pet with just a few clicks. Pay securely and enjoy
                your time.
              </p>
            </div>
            <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <img src={listPetImage} alt="List Pet" className="mx-auto mb-4" />
              <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                List Your Pet
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Share your pet with others when you’re busy. Earn extra income
                by renting them out.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="bg-gradient-to-b from-blue-100 to-blue-50 py-12">
        <div className="max-w-7xl mx-auto text-center text-black space-y-6">
          <h2 className="text-3xl font-bold">Ready to Get Started?</h2>
          <p className="text-lg">
            Whether you’re looking to rent a pet or rent out your own, it’s easy
            to get started!
          </p>
          <div className="flex justify-center space-x-4">
            <Link to="/rent">
              <Button
                as="a"
                variant="solid"
                className="bg-blue-600 text-white hover:bg-blue-500 hover:cursor-pointer"
              >
                Rent a Pet
              </Button>
            </Link>
            <Link to="/list-pet">
              <Button
                as="a"
                variant="solid"
                className="bg-blue-600 text-white hover:bg-blue-500 hover:cursor-pointer"
              >
                List Your Pet
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
